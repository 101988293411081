<template>
  <div class="fast-search secondary lighten-2">
    <category-title :category="category" />
    <proposal-slider :proposals="headerBanner" />
    <div class="secondary lighten-2">
      <v-container>
        <!-- <breadcrumb v-if="$vuetify.breakpoint.smAndUp" :items="breadcrumb" /> -->
        <proposal-slider :proposals="headerBanner" />

        <div class="search-result" v-for="q in query" :key="q">
          <SimpleProductListSlider
            :query="q"
            :paginationClass="'swiper-pagination-' + q"
          />
          <v-divider class="mb-6"></v-divider>
        </div>
      </v-container>
    </div>
    <proposal-slider :proposals="footerBanner" />
  </div>
</template>
<script>
import SimpleProductListSlider from "@/components/product/SimpleProductListSlider.vue";
import ProposalSlider from "@/components/proposal/ProposalSlider.vue";
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import { mapProposal } from "~/service/ebsn.js";

import categoryMixins from "~/mixins/category";

export default {
  name: "FastSearch",
  components: {
    SimpleProductListSlider,
    ProposalSlider,
    CategoryTitle
    // Breadcrumb
  },
  mixins: [categoryMixins],
  data() {
    return {
      query: []
    };
  },
  computed: {
    ...mapProposal({
      headerBanner: "header-banner",
      footerBanner: "footer-banner"
    })
  },
  methods: {
    getQueryParams() {
      this.query = this.$route.query.q;
    }
  },
  async created() {
    this.getQueryParams();
  },
  watch: {
    async "$route.query"() {
      this.getQueryParams();
    }
  }
};
</script>
